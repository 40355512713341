<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId === formattedChatData.contact.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar"
        />
      </div>
      <div
        v-for="msgData in msgGrp.messages"
        :key="msgData.time"
      >
        <div
          class="chat-body"
        >
          <div

            v-b-popover.hover.left="msgData.time"
            class="chat-content"
          >

            <p>{{ msgData.msg }}</p>
            <div
              v-if="msgData.img.length"
              class="chat-contsent"
            >
              <!-- <div v-if="msgData.img.length > 1"class="table-container"> -->
              <div :class="{'row':msgData.img.length>1}">
                <div
                  v-for="imageOr in msgData.img"
                  :key="imageOr"
                  class="chat-connect-custom mx-auto"
                >
                  <img
                    v-if="msgData.fileimgcheck"
                    :src="imageOr"
                    alt="Preview"
                    width="50"
                    class="text-center"
                    @click="showPicture(imageOr)"
                  >
                  <img
                    v-if="!msgData.fileimgcheck"
                    :src="`https://api.anan-cargo.com/api/Chat/getimg/${imageOr}`"
                    alt="Preview"
                    width="50"
                    class="text-center"
                    @click="showPicture(`https://api.anan-cargo.com/api/Chat/getimg/${imageOr}`)"
                  >

                  <!-- <img
                    :src="`http://127.0.0.1:4444/api/Chat/getimg/${imageOr}`"
                    alt="Preview"
                    width="50"
                    class="text-center"
                    @click="showPicture(`http://127.0.0.1:4444/api/Chat/getimg/${imageOr}`)"
                  > -->

                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- <div>
          <small class="chat-content">{{ msgData.time }}</small>
        </div> -->
      </div>

    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
      
    />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar, VBPopover } from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BAvatar,
    VueImageLightboxCarousel,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      images: [],
      showLightbox: false,
    }
  },
  methods: {
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }
      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            img: msg.fileimg,
            time: msg.time,
            fileimgcheck: msg.fileimgcheck,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              img: msg.fileimg,
              time: msg.time,
              fileimgcheck: msg.fileimgcheck,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })
    return {
      formattedChatData,
    }
  },
}
</script>

<style scoped>
</style>
