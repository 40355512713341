<template>
  <div :class="contentWidth === 'boxed' ? null : null">
    <this-header />
    <div class="col-sm-12 ">
      <router-view />
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <chat />
    <MenuPhone class="d-md-none" style="margin-top: 100px !important;" />
  </div>
</template>

<script>
import chat from "@/views/customer/chat/chat.vue";
import ThisHeader from "@/views/customer/component/ThisHeader.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import MenuPhone from "@/views/customer/profile/component/MenuPhone.vue";

export default {
  components: {
    ThisHeader,
    chat,
    MenuPhone,
  },
  data() {
    return {
      // Token: [],
    };
  },
  setup() {
    const { contentWidth } = useAppConfig();
    return { contentWidth };
  },
  // mounted() {
  //   this.Token = localStorage.getItem('token')
  // },
};
</script>
